<template>
  <div class="numpad-button" :class="{disabled: disabled}">
    <span v-if="text">{{ text }}</span>
    <span v-if="icon" class="material-icons">{{ icon }}</span>
  </div>
</template>

<script>
export default {
  name: 'NumPadButton',
  props: {
    text: {
      type: [String, Number],
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    disabled: Boolean
  },
}
</script>

<style lang="postcss" scoped>
.numpad-button {
  @apply cursor-pointer select-none grid place-content-center;
  @apply w-[56px] h-[56px] text-[24px] text-center rounded-sub;
  @apply text-primary-100 bg-primary-30;
  box-shadow: 0px 1px 10px 1px rgba(95, 95, 95, 0.15);
}

.numpad-button:hover {
  @apply bg-primary-100 text-primary-30 transition ease-in-out duration-300;
}

.disabled {
  @apply bg-[#DEDEDE] text-white pointer-events-none cursor-not-allowed;
}
</style>
