<template>
  <div class="num-Input">
    <div class="show-num" :class="{'disabled-text': disabled}">{{ syncModel === '' ? '請輸入' : syncModel }}</div>
    <div class="phone-pad">
      <NumPadButton
        v-for="(num, index) in 9"
        :key="`num-${index}`"
        :text="num"
        :disabled="disabled"
        @click.native="addNum(num.toString())"
      />
      <NumPadButton
        text="重置"
        style="font-size: 18px;"
        :disabled="disabled"
        @click.native=";(syncModel = ''), $emit('reset')"
      />
      <NumPadButton :disabled="disabled" text="0" @click.native="addNum('0')" />
      <NumPadButton :disabled="disabled" icon="arrow_back" @click.native="syncModel = syncModel.slice(0, syncModel.length - 1)" />
    </div>
  </div>
</template>

<script>
import NumPadButton from './components/NumPadButton.vue'
export default {
  name: 'PhonePad',
  components: { NumPadButton },
  props: ['model', 'type', 'max', 'disabled'],
  computed: {
    syncModel: {
      get () {
        return this.model
      },

      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  methods: {
    addNum (num) {
      if (this.syncModel.length === 10) return
      this.syncModel += num
    },

    confirm () {
      this.$emit('close')
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.show-num {
  @apply text-gray-80 m-[24px] text-[26px];
}

.num-Input {
  @apply w-full flex flex-col items-center justify-center;
}

.phone-pad {
  @apply m-auto grid gap-[32px];
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
}

.disabled-text {
  @apply text-gray-60 select-none;
}
</style>
