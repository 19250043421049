<template>
  <div v-if="config" class="sales-record-login">
    <!-- 非會員列表 -->
    <div class="null-member-list w-full flex flex-col items-end">
      <div
        v-if="isNullMember"
        class="member-item null"
        @click="selectMember(membersList[0].UserInfo.phone, false)"
      >
        <span>{{ membersList[0].UserInfo.name }}</span>
        <span>{{ membersList[0].UserInfo.phone }}</span>
      </div>
    </div>
    <div class="flex flex-col justify-center items-center" style="gap: 60px; margin: 0 60px;">
      <PhonePad
        :disabled="disabledPhonePad"
        :model.sync="phoneSearch"
        @reset="membersList = []"
      />

      <div v-if="isSpecifyDate" class="flex flex-col justify-center items-center" style="gap: 8px;">
        <div class="flex items-center" style="gap: 8px;">
          <p class="text-left">
            <span class="text-danger font-bold">*</span>
            銷售紀錄建立時間
          </p>
          <p class="text-sm text-gray-60">僅限 7 天內</p>
        </div>

        <el-date-picker
          v-model="createDate"
          :picker-options="pickerOptions"
          type="datetime"
          format="yyyy/MM/dd HH:mm"
          placeholder="選擇日期"
          @change="onCreateDateChange"
        />
      </div>
    </div>
    <!-- 會員列表 -->
    <div class="member-list w-full flex flex-col items-start">
      <div
        v-for="member in membersList"
        v-show="member.UserInfo.name !== '非會員'"
        :key="member.id"
        class="member-item"
        @click="selectMember(member.id, true)"
      >
        <span>{{ member.UserInfo.name }}</span>
        <span>{{ member.UserInfo.phone }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, computed } from 'vue'
import PhonePad from '@/components/Numpad/PhonePad.vue'
import { GetMembers } from '@/api/member'
import { GetSalesConfig } from '@/api/sales'
import { useShop } from '@/use/shop'
import { useRoute, useRouter } from 'vue-router/composables'
import dayjs from 'dayjs'
import { set as setAttr } from 'lodash'

const warnUnload = (e) => {
  e.preventDefault()
  return (e.returnValue = '')
}

export default defineComponent({
  name: 'SalesRecordCreate',
  components: { PhonePad },
  setup () {
    const { shopId } = useShop()
    const route = useRoute()
    const router = useRouter()
    const config = ref(null)
    const phoneSearch = ref('09')
    const membersList = ref([])
    const createDate = ref(null)
    const nullMember = reactive({
      name: '非會員',
      phone: '',
      id: 'nullMember',
    })

    const isSpecifyDate = computed(() => {
      return route.meta.specifyDate === true
    })

    const isNullMember = computed(() => {
      let isNull = false
      if (phoneSearch.value.length === 10 && membersList.value.length > 0) {
        if (membersList.value[0].UserInfo.name === '非會員') isNull = true
      }
      return isNull
    })

    const pickerOptions = {
      disabledDate: (date) => {
        const minDate = dayjs().subtract(6, 'd')
        const maxDate = dayjs()
        const cur = dayjs(date)
        return cur.isBefore(minDate, 'd') || cur.isAfter(maxDate, 'd')
      },
    }

    const selectMember = (memberId, isMember) => {
      const routePath = {
        name: 'SalesRecordCreate',
        params: {
          id: memberId,
        },
      }
      if (isMember) { routePath.params.isMember = Boolean(isMember) }
      if (isSpecifyDate.value) {
        const specifyDate = createDate.value
        if (!specifyDate) {
          window.$message.warning('請選擇日期 !')
          return
        }
        setAttr(routePath, 'query.recordAt', dayjs(specifyDate).valueOf())
      }
      router.push(routePath)
    }

    const disabledPhonePad = computed(() => {
      if (!isSpecifyDate.value) return false
      if (!createDate.value) return true
      return false
    })

    const onCreateDateChange = (date) => {
      if (date) phoneSearch.value = '09'
      else phoneSearch.value = '請先選擇日期'
    }

    return {
      shopId,
      isNullMember,
      config,
      phoneSearch,
      membersList,
      nullMember,
      createDate,
      isSpecifyDate,
      pickerOptions,
      selectMember,
      disabledPhonePad,
      onCreateDateChange,
    }
  },
  watch: {
    async phoneSearch () {
      if (this.phoneSearch.length < 5) {
        this.membersList = []
        return
      }
      await this.remoteSearch()
    },
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', warnUnload, { capture: true })
  },

  async mounted () {
    window.addEventListener('beforeunload', warnUnload, { capture: true })

    await this.getSalseConfig()
    if (this.config.identityMode) {
      this.$router.push('/sales/sales-record-create/identity')
      return
    }
    if (this.isSpecifyDate) {
      this.phoneSearch = '請先選擇日期'
    }
  },

  methods: {
    async getSalseConfig () {
      try {
        this.config = await GetSalesConfig({ shopId: this.shopId })
      } catch (error) {
        console.log(error)
      }
    },

    //= > 即時電話搜尋會員
    async remoteSearch () {
      const query = this.phoneSearch
      if (query === '') this.membersList = []

      try {
        setTimeout(async () => {
          const res = await GetMembers({
            shopId: this.shopId,
            phone: query,
          })
          this.membersList = res.slice(0, 3)
          if (query.length === 10 && this.membersList.length === 0) {
            this.membersList.push({
              UserInfo: {
                name: '非會員',
                phone: query,
              },
            })
          }
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },

  },
})
</script>

<style scoped lang="scss">
.sales-record-login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.member-list,
.null-member-list {
  @apply mt-[50px];
}

.member-item {
  @apply text-gray-80 cursor-pointer w-[232px];
  @apply flex justify-between mb-[20px] rounded-sm p-[12px] font-normal;
  @apply border border-primary-100 bg-primary-100 text-white;
  // background: white;
}

.null {
  @apply bg-white text-gray-100;
  // color: white;
  // background: $tertiary-1;
}
</style>
